import React from "react"
import { Col, Divider, List, Row, Select, Typography } from "antd"
import { graphql } from "gatsby"
import Shell from "../../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../../components/SharpHero/SharpHero"
import BookingBar from "../../components/BookingBar/BookingBar"
import PackagesWidget from "../../components/PackagesWidget/PackagesWidget"
import SocialSharing from "../../components/SocialSharing/SocialSharing"

const description = `Asheville is home to some of the best dining in North Carolina. Whether you venture downtown, to the River Arts District, South Slope, West Asheville, or near Biltmore, there are plenty of fantastic eateries for tempting any palate.`

const rowProps = {
  align: `middle`,
  gutter: [24, 24],
  justify: `center`,
  type: `flex`,
  style: {
    paddingTop: 24,
  },
}

const colProps = {
  xs: 23,
  sm: 22,
  md: 20,
  lg: 18,
  xl: 16,
  xxl: 14,
}

export default ({ data: { hero, restaurants }, location }) => {
  const [options, setOptions] = React.useState(restaurants.nodes)

  const handleSelect = value => {
    if (value === `All`) {
      setOptions(restaurants.nodes)
    } else {
      setOptions(restaurants.nodes.filter(({ name }) => name === value))
    }
  }

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        title="Asheville NC Dining - The Residences at Biltmore"
        url={location.href}
      />
      <Hero contentfulAsset={hero} height="40vh" />
      <BookingBar />
      <Row {...rowProps}>
        <Col {...colProps}>
          <Typography.Title>Dining & Nightlife</Typography.Title>
        </Col>
        <Col {...colProps}>
          <Typography.Paragraph>
            With over 250 independent restaurants, Asheville has earned the
            respect of food enthusiasts nationwide and its restaurant community
            is well-known for its ethos of using locally grown, raised, and
            sourced ingredients.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Whether you venture downtown, to the River Arts District, South
            Slope, West Asheville, or near Biltmore, there are plenty of
            fantastic eateries to tempt any palate. From good old southern-style
            comfort food and exquisite BBQ, to fine dining and hand-made
            desserts, Asheville has it all.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Showcased here are some of our favorite places to eat in the area,
            as well as a bit of background for each to help you decide which
            places fit your dining preferences.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...colProps}>
          <Typography.Title level={3}>Select a Cuisine</Typography.Title>
          <Select
            defaultValue="Show All"
            onChange={handleSelect}
            size="large"
            style={{ width: 295 }}
          >
            <Select.Option value="All">Show All</Select.Option>
            {restaurants.nodes.map(({ name }, index) => (
              <Select.Option key={index} value={name}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col {...colProps}>
          <List
            dataSource={options}
            itemLayout="vertical"
            renderItem={({ name, members }, index) => (
              <List.Item key={index}>
                <List
                  dataSource={members}
                  header={<Typography.Title level={3}>{name}</Typography.Title>}
                  itemLayout="vertical"
                  split={false}
                  renderItem={({ about, name, url }, index) => (
                    <div
                      style={{
                        background: !!(index % 2)
                          ? `inherit`
                          : `rgba(0,0,0,0.025)`,
                        margin: `12px auto`,
                        padding: `8px 24px`,
                      }}
                    >
                      <List.Item
                        actions={[
                          <a href={url} style={{ textDecoration: `underline` }}>
                            Visit Website
                          </a>,
                        ]}
                        key={index}
                      >
                        <List.Item.Meta
                          description={
                            <span style={{ color: `rgba(0,0,0,.85)` }}>
                              {about}
                            </span>
                          }
                          title={<span style={{ fontSize: 20 }}>{name}</span>}
                        />
                      </List.Item>
                    </div>
                  )}
                />
              </List.Item>
            )}
          />
        </Col>
        <Divider />
        <Col span={23}>
          <SocialSharing pageUrl={location.href} />
        </Col>
      </Row>
      <PackagesWidget />
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "RAB - Dining Hero" }) {
      ...HeroFluid
    }
    restaurants: allRestaurantsJson {
      nodes {
        members {
          about
          name
          url
        }
        name
      }
    }
  }
`
